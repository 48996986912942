<template>
    <el-dialog :title="title" width="40%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <el-form size="small" :model="inputForm" ref="inputForm" :rules="dataRule" v-loading="loading"
                 label-width="120px">
            <el-form-item label="名称：" prop="archivesBasicValue">
                <el-input v-model.trim="inputForm.archivesBasicValue" maxlength="10"
                          placeholder="请输入元数据选项名称（限10字）" clearable class="w90"
                          @input="nameChange"></el-input>
            </el-form-item>
            <el-form-item label="元数据名称：" prop="archivesBasicId">
                <el-select v-model="inputForm.archivesBasicId" placeholder="请选择元数据名称" filterable clearable
                           class="w90">
                    <el-option v-for="item in archivesBasicOpt" :key="item.id" :label="item.basicName"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选项：" prop="selectBy">
                <el-radio-group v-model="inputForm.selectBy">
                    <el-radio :label="item.value" :key="item.value"
                              v-for="item in $dictUtils.getDictList('metadata_option')">{{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer text_center">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            visible: false,
            method: '',
            loading: false,
            inputForm: {
                id: '',
                archivesBasicValue: '',
                archivesBasicId: '',
                selectBy: '0',
            },
            archivesBasicOpt: [],
            dataRule: {
                archivesBasicValue: [
                    {required: true, message: '名称不能为空', trigger: 'blur'},
                    {validator: this.validator.isEmoji, trigger: 'blur'}
                ],
                archivesBasicId: [
                    {required: true, message: '数据名称不能为空', trigger: 'change'}
                ]
            }
        }
    },
    methods: {
        init(row, method) {
            this.getArchives()
            this.loading = false
            this.method = method
            this.inputForm.id = ''
            if (method === 'add') {
                this.title = '新增元数据选项'
            } else if (method === 'edit') {
                this.title = '修改元数据选项'
            }
            this.visible = true
            this.$nextTick(() => {
                this.$refs['inputForm'].resetFields()
                if (method === 'edit') {
                    this.inputForm = this.recover(this.inputForm, row)
                }
            })
        },
        // 获取元数据名称
        getArchives() {
            this.$axios(this.api.record.selectAllTextArchivesBasic).then((res) => {
                if (res.status) {
                    this.archivesBasicOpt = res.data
                }
            })
        },
        nameChange(val) {
            if (val.length >= 50) {
                this.$message.warning('输入已达上限！')
            }
        },
        // 提交
        doSubmit() {
            this.$refs['inputForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    let api = this.api.record.multiselectSave, type = 'post'
                    if (this.method === 'edit') {  // 修改
                        api = this.api.record.multiselectUpdateById
                        type = 'put'
                    }
                    this.$axios(api, this.inputForm, type).then(data => {
                        this.loading = false
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.visible = false
                            this.$emit('refreshDataList')
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                }
            })
        },
    },
}
</script>

<style scoped>

</style>
